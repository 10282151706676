<template>
  <div class="blog-sidebar-widget">
    <h3>related Post</h3>
    <ul class="featured-list">
      <li class="sidebr-pro-widget" v-for="(item, key) in relatedPost" :key="key">
        <div class="blog-thumb-info">
          <div class="blog-thumb-info-image">
            <b-link>
              <img :src="item.img" alt="proudct" />
            </b-link>
          </div>
          <div class="blog-thumb-info-content">
            <h4><b-link>{{item.title}}</b-link></h4>
            <p>Posted on: <b-link>{{item.date}}</b-link></p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "BlogRelatedPost",
  data() {
    return {
      relatedPost: [
        {
          img: require('../../assets/img/img-1.jpg'),
          title: 'get your construction permit',
          date: 'Apr 25, 2023'
        },
        {
          img: require('../../assets/img/img-2.jpg'),
          title: 'international trading',
          date: 'Apr 25, 2023'
        },
        {
          img: require('../../assets/img/img-3.jpg'),
          title: 'mergers and acquisitions',
          date: 'Apr 25, 2023'
        },
        {
          img: require('../../assets/img/img-1.jpg'),
          title: 'how to prepare for court',
          date: 'Apr 25, 2023'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>