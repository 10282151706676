<template>
  <div class="blog-sidebar-widget">
    <h3>tags</h3>
    <ul class="Tags-catagory">
      <li v-for="(item, key) in tags" :key="key"><b-link>{{item}}</b-link></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "BlogTag",
  data() {
    return {
      tags: ['business', 'investment', 'Audit', 'assurance', 'consulting', 'partnership', 'life', 'Security']
    }
  }
}
</script>

<style scoped>

</style>