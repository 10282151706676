<template>
  <!-- Blog Page Area Start -->
  <section class="ekifa-blog-page-area section_80">
    <b-container>
      <b-row>
        <b-col lg="8" md="12" sm="12">
          <!-- Blog Post Area -->
          <blog-post/>
        </b-col>
        <b-col lg="4" md="12" sm="12">
          <blog-right-side-area/>
        </b-col>
      </b-row>
    </b-container>
  </section>
  <!-- Blog Page Area End -->
</template>

<script>
import BlogPost from "./BlogPost";
import BlogRightSideArea from "./BlogRightSideArea";

export default {
  name: "BlogArea",
  components: {BlogRightSideArea, BlogPost}
}
</script>

<style scoped>

</style>