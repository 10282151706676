<template>
  <div>
    <div class="blog-page-left">
      <b-row>
        <template v-for="(item, key) in posts">
          <b-col md="12" lg="6" :key="key">
            <div class="single-blog">
              <div class="blog-img">
                <b-link>
                  <img :src="item.img" alt="blog" />
                </b-link>
              </div>
              <div class="blog-text">
                <div class="blog-meta">
                  <p><i class="fa fa-user"></i> {{item.postBy}}</p>
                  <p><i class="fa fa-clock-o"></i> {{item.date}}</p>
                </div>
                <h3><b-link :to="item.path">{{item.title}}</b-link></h3>
                <p>{{item.description}}</p>
              </div>
              <div class="blog-btn">
                <b-link :to="item.path"><i class="fa fa-arrow-right"></i></b-link>
              </div>
            </div>
          </b-col>
        </template>
      </b-row>
    </div>
    <!--Blog Pagination Area-->
    <blog-pagination/>
  </div>
</template>

<script>
import BlogPagination from "./BlogPagination";
export default {
  name: "BlogPost",
  components: {BlogPagination},
  data() {
    return {
      posts: [
        {
          img: require('../../assets/img/blog-1.jpeg'),
          postBy: 'By Jamil Fares',
          path: '/blog/single-blog',
          date: '05 Feb, 2023',
          title: 'Land Ownership',
          description: 'Aliquip exa consequat dui aut repahend vouptate elit cilum fugiat pariatur lorem dolor cit amet consecter adipisic elit sea vena eiusmod nulla'
        },
        {
          img: require('../../assets/img/blog-2.jpeg'),
          postBy: 'By Christelle Helou',
          path: '/blog/single-blog',
          date: '14 Feb, 2023',
          title: 'How to choose your lawyer',
          description: 'Aliquip exa consequat dui aut repahend vouptate elit cilum fugiat pariatur lorem dolor cit amet consecter adipisic elit sea vena eiusmod nulla'
        },
        {
          img: require('../../assets/img/blog-3.jpeg'),
          postBy: 'By Jamil Fares',
          path: '/blog/single-blog',
          date: '14 Feb, 2023',
          title: 'How to prepare your will inheritance',
          description: 'Aliquip exa consequat dui aut repahend vouptate elit cilum fugiat pariatur lorem dolor cit amet consecter adipisic elit sea vena eiusmod nulla'
        },
        {
          img: require('../../assets/img/blog-1.jpeg'),
          postBy: 'By Jamil Fares',
          path: '/blog/single-blog',
          date: '11 Feb, 2023',
          title: 'How to incorporate your company',
          description: 'Aliquip exa consequat dui aut repahend vouptate elit cilum fugiat pariatur lorem dolor cit amet consecter adipisic elit sea vena eiusmod nulla'
        },
        {
          img: require('../../assets/img/blog-2.jpeg'),
          postBy: 'By Christelle Helou',
          path: '/blog/single-blog',
          date: '14 Feb, 2023',
          title: 'Different court types',
          description: 'Aliquip exa consequat dui aut repahend vouptate elit cilum fugiat pariatur lorem dolor cit amet consecter adipisic elit sea vena eiusmod nulla'
        },
        {
          img: require('../../assets/img/blog-3.jpeg'),
          postBy: 'By Christelle Helou',
          path: '/blog/single-blog',
          date: '14 Feb, 2023',
          title: 'How to prepare for your copyrights',
          description: 'Aliquip exa consequat dui aut repahend vouptate elit cilum fugiat pariatur lorem dolor cit amet consecter adipisic elit sea vena eiusmod nulla'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>