<template>
  <div class="blog-sidebar-widget">
    <h3>by Categories</h3>
    <ul class="blog-categories">
      <li><b-link><i class="fa fa-angle-double-right "></i> business</b-link></li>
      <li><b-link><i class="fa fa-angle-double-right "></i> consulting </b-link></li>
      <li><b-link><i class="fa fa-angle-double-right "></i> business partnership</b-link></li>
      <li><b-link><i class="fa fa-angle-double-right "></i> Audit & assurance</b-link></li>
      <li><b-link><i class="fa fa-angle-double-right "></i> investment</b-link></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "BlogCategory"
}
</script>

<style scoped>

</style>