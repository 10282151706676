<template>
  <div class="pagination_box">
    <ul>
      <li><b-link>1</b-link></li>
      <li class="active"><span>2</span></li>
      <li><b-link><i class="fa fa-long-arrow-right"></i></b-link></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "BlogPagination"
}
</script>

<style scoped>

</style>