<template>
  <div>
    <!-- Blog Search Area -->
<!--    <blog-search/>-->
    <!-- Blog Social Area -->
<!--    <blog-social/>-->
    <!-- Blog Category Area -->
    <blog-category/>
    <!-- Blog Tags Area -->
    <blog-tag/>
    <!-- Blog Related Post Area -->
    <blog-related-post/>
    <!-- Blog Instagram Area -->
<!--    <blog-instagram/>-->
  </div>
</template>

<script>
// import BlogSearch from "./BlogSearch";
// import BlogSocial from "./BlogSocial";
import BlogCategory from "./BlogCategory";
import BlogTag from "./BlogTag";
import BlogRelatedPost from "./BlogRelatedPost";
// import BlogInstagram from "./BlogInstagram";
export default {
  name: "BlogRightSideArea",
  components: {
    // BlogInstagram,
    BlogRelatedPost,
    BlogTag,
    BlogCategory
    // BlogSocial
    // BlogSearch
  }
}
</script>

<style scoped>

</style>